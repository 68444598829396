import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid default-dash" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent, ref, onMounted } from 'vue';
  import { fetchDashboardData } from '@/api/dashboard';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'indexDefault',
  setup(__props) {

  const totalVisitor = defineAsyncComponent(() => import("@/components/widget/general/totalVisitor.vue"));
  const totalIdentified = defineAsyncComponent(() => import("@/components/widget/general/totalIdentified.vue"));
  //const companyView = defineAsyncComponent(() => import("@/components/dashbords/default/companyView.vue"));
  const visitorGraph = defineAsyncComponent(() => import("@/components/dashbords/default/visitorGraph.vue"));
  const identificationGraph = defineAsyncComponent(() => import("@/components/dashbords/default/identificationGraph.vue"));
  
  const visitorData = ref<{
    total: number;
    emailTotal: number;
    phoneTotal: number;
    totalInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
  }>({
    total: 0,
    emailTotal: 0,
    phoneTotal: 0,
    totalInMonth: 0,
    dayWise: []
  });
  
  const identificationData = ref<{
    total: number;
    totalInMonth: number;
    totalReVisitor: number;
    totalReVisitorInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
  }>({
    total: 0,
    totalInMonth: 0,
    totalReVisitor: 0,
    totalReVisitorInMonth: 0,
    dayWise: []
  });
  
  async function fetchDashboardDataAsync() {
    try {
      const { visitor, identification } = await fetchDashboardData();
      visitorData.value = visitor;
      identificationData.value = identification;
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }
  
  onMounted(() => {
    fetchDashboardDataAsync();
  });
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(totalVisitor), { visitorData: visitorData.value }, null, 8, ["visitorData"]),
      _createVNode(_unref(totalIdentified), { identificationData: identificationData.value }, null, 8, ["identificationData"]),
      _createVNode(_unref(visitorGraph), {
        visitorData: visitorData.value.dayWise
      }, null, 8, ["visitorData"]),
      _createVNode(_unref(identificationGraph), {
        identificationData: identificationData.value.dayWise
      }, null, 8, ["identificationData"])
    ])
  ]))
}
}

})