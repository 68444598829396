import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-wrapper row m-0" }
const _hoisted_2 = { class: "header-logo-wrapper col-auto p-0" }
const _hoisted_3 = { class: "nav-right col-8 pull-right right-header p-0" }
const _hoisted_4 = { class: "nav-menus" }
const _hoisted_5 = { class: "profile-nav onhover-dropdown p-0 me-0" }
const _hoisted_6 = { class: "credit-balance onhover-dropdown" }
const _hoisted_7 = { class: "profile-nav onhover-dropdown p-0 me-0" }

import { defineAsyncComponent } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'indexView',
  setup(__props) {

const sidebar = defineAsyncComponent(() => import("./sidebarView.vue"))
const agencydropView = defineAsyncComponent(() => import("./agencydropView.vue"))
const searchView = defineAsyncComponent(() => import("./searchView.vue"))
const notificationView = defineAsyncComponent(() => import("./notificationView.vue"))
const messagesView = defineAsyncComponent(() => import("./messagesView.vue"))
const modeView = defineAsyncComponent(() => import("./modeView.vue"))

const profileView = defineAsyncComponent(() => import('./profileView.vue'))


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(sidebar))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_unref(agencydropView))
          ])
        ]),
        _createElementVNode("li", _hoisted_6, [
          _createVNode(_unref(messagesView))
        ]),
        _createElementVNode("li", _hoisted_7, [
          _createVNode(_unref(profileView))
        ])
      ])
    ])
  ]))
}
}

})